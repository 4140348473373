import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CalculadoraService } from 'src/app/calculadora/services/calculadora.service';
import { infoIcon } from 'src/assets/base64/images';

@Component({
  selector: 'app-calculate',
  templateUrl: './app-calculate.component.html',
  styleUrls: ['./app-calculate.component.scss']
})
export class AppCalculateComponent implements OnChanges {

  sendOutputDataInterval;
  outOfAmountBounds = false;

  editMode = false;

  months = [];
  selectedMonth: number;
  selectOpen = false;
  infoIcon = this._sanitizer.bypassSecurityTrustResourceUrl(infoIcon);

  @Input() values;
  @Output() outputData = new EventEmitter<any>();
  @Output() outputMonths = new EventEmitter<any>();

  @ViewChild('inputEdit', { static: false }) inputEdit: ElementRef<HTMLInputElement>;

  constructor(
    private calculadoraService: CalculadoraService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes) {
    if (changes && changes.values.currentValue) {
      this.calculateMonthsRange(changes.values.currentValue);
      this.calculatePreviousMonthPosition();
    }
  }

  calculateMonthsRange(values) {
    const numberOfElements = (values.Deadline_Max - values.Deadline_Min) / values.Length_Deadlines; // casuística de número de meses no encontrado antes 12 y luego 24 
    let max = values.Deadline_Max;
    this.months = new Array(numberOfElements);
    for (let i = this.months.length; i >=0 ; i--) {
      this.months[i] = max;
      max = max - values.Length_Deadlines;
    }
  }

  calculatePreviousMonthPosition(){
    if (!!this.selectedMonth) {
      const sameMonth = this.values.Deadlines === this.selectedMonth;
      const isPresent = this.months.find( month => month === this.selectedMonth);
      this.selectedMonth =
          this.selectedMonth > this.months[this.months.length -1] ? this.months[this.months.length -1] :
            (this.selectedMonth < this.months[0] ? this.months[0] : isPresent);
      this.values.Deadlines = this.selectedMonth ? this.selectedMonth : this.values.Deadlines;
      this.outputMonths.emit(this.values.Deadlines);
    }
  }

  onMonthClick(month) {
    this.values.Deadlines = month;
    this.selectedMonth = month
    this.sendOutputData();
  }

  onSelectClick() {
    this.selectOpen = !this.selectOpen;
  }

  checkOutOfBounds(timeout?: number, ammountValue?: number) {
    this.checkIllegalCharsAtEditAmountInput();
    timeout = !!timeout ? timeout : 0;
    ammountValue = !!ammountValue ? this.numberTreatment(ammountValue.toString()) : +this.values.amount;
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
    if (
      this.editMode ?
        !!this.inputEdit.nativeElement.value &&
        !!ammountValue &&
        ammountValue >= +this.values.Amount_Min &&
        ammountValue <= +this.values.Amount_Max
      :
        !!ammountValue &&
        ammountValue >= +this.values.Amount_Min &&
        ammountValue <= +this.values.Amount_Max
    ) {

      this.outOfAmountBounds = false;
      this.calculadoraService.amountOutOfBounds$.next(false);
      this.sendOutputDataInterval = setInterval( () => {
        this.sendOutputData(ammountValue);
      }, timeout);

    } else {
      this.outOfAmountBounds = true;
      this.calculadoraService.amountOutOfBounds$.next(true);
    }
  }

  checkIllegalCharsAtEditAmountInput() {
    if (!!this.editMode) {
      this.inputEdit.nativeElement.value = this.numberTreatment(this.inputEdit.nativeElement.value).toString();
      if (this.inputEdit.nativeElement.value === '0' || isNaN(+this.inputEdit.nativeElement.value)) {
        this.inputEdit.nativeElement.value = '';
      }
    }
  }

  sendOutputData(ammountValue?) {
    if (ammountValue) {
      this.values.Amount = ammountValue;
    }
    this.outOfAmountBounds = false;
    this.calculadoraService.amountOutOfBounds$.next(false);
    this.outputData.emit(this.values);
    if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    }
  }

  numberTreatment(value: string) {
    return +Number((value.replace(/,/g, '.')).replace(/[^.0-9]/g, '')).toFixed(2);
  }

  removeEditMode() {
    /* if (!!this.sendOutputDataInterval) {
      clearInterval(this.sendOutputDataInterval);
    } */
    this.editMode = false;
    // this.checkOutOfBounds();
  }

  setValueToModel(qty) {
    this.values.amount = qty.value;
    this.checkOutOfBounds(1000);
  }

  setFocusToEditInput() {
    this.editMode = true;
    setTimeout( () => {
      this.inputEdit.nativeElement.focus();
      this.inputEdit.nativeElement.setSelectionRange(0, 0);
    }, 0);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LoanProposalModel } from '../../models/InitDetails.model';
import { CalculadoraService } from '../../services/calculadora.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './app-dropdown.component.html',
  styleUrls: ['./app-dropdown.component.scss']
})
export class AppDropdownComponent implements OnInit {

  calculateValues = {
    Amount: 0,
    Amount_Min: 0,
    Amount_Max: 0,
    Monthly_Fee: 0,
    Deadline_Min: 0,
    Deadline_Max: 0,
    Deadlines: 0,
    Length_Amount: 1,
    Length_Deadlines: 1,
  };

  initData;

  outOfAmountBounds = false;

  @Input() initData$: Observable<LoanProposalModel>;
  @Input() accesstoken: string;

  @Output() outputData = new EventEmitter<any>();
  @Output() outputMonths = new EventEmitter<any>();

  constructor(
    private calculadoraService: CalculadoraService
  ) {}

  ngOnInit() {
    this.initData$
    .pipe(
      filter(data => !!data)
    )
    .subscribe( (data: LoanProposalModel) => {
      this.initData = {...data};

      this.calculateValues = {
        Amount: data.Amount,
        Amount_Min: data.Amount_Min,
        Amount_Max: data.Amount_Max,
        Monthly_Fee: data.Monthly_Fee,
        Deadline_Min: data.Deadline_Min,
        Deadline_Max: data.Deadline_Max,
        Deadlines: data.Deadlines,
        Length_Amount: data.Length_Amount,
        Length_Deadlines: data.Length_Deadlines
      };

    });
  }

  calculate(values: any) {

    this.initData = {...this.initData, ...values}

    this.calculadoraService.getCalculate(
      this.accesstoken,
      this.initData
    ).pipe(
      filter( data => !!data)
    ).subscribe( (data: LoanProposalModel)  => {
      this.initData = {...this.initData, ...data};
      this.calculateValues = {
        Amount: data.Amount,
        Amount_Min: data.Amount_Min,
        Amount_Max: data.Amount_Max,
        Monthly_Fee: data.Monthly_Fee,
        Deadline_Min: data.Deadline_Min,
        Deadline_Max: data.Deadline_Max,
        Deadlines: data.Deadlines,
        Length_Amount: data.Length_Amount,
        Length_Deadlines: data.Length_Deadlines
      };

      this.outputData.emit({...this.initData});
    });
  }

  broadCastAmountValue(event) {
    this.calculate(event);
  }

  broadCastMonths(event) {
    this.outputMonths.emit(event);
  }
}

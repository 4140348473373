import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoanProposalModel } from './models/InitDetails.model';
import { AmortizationTableModel } from './models/AmortizationTable.model';
import { CalculadoraService } from './services/calculadora.service';
import { closeIcon, conditionsIcon, logoImage } from 'src/assets/base64/images';
import { DomSanitizer } from '@angular/platform-browser';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-calculadora',
  templateUrl: './app-calculadora.component.html',
  styleUrls: ['./app-calculadora.component.scss']
})
export class AppCalculadoraComponent implements OnInit{

  accesstoken = '12345'; // TODO: Remove & change by real data

  initData$ = new BehaviorSubject<LoanProposalModel>(null);
  calculosData$ = new BehaviorSubject<LoanProposalModel>(null);
  initData: LoanProposalModel;
  viewListData = false;
  listData: AmortizationTableModel;
  amountOutOfBounds = false;
  logoImage = this._sanitizer.bypassSecurityTrustResourceUrl(logoImage);
  closeIcon = this._sanitizer.bypassSecurityTrustResourceUrl(closeIcon);
  conditionsIcon = this._sanitizer.bypassSecurityTrustResourceUrl(conditionsIcon);

  constructor(
    private calculadoraService: CalculadoraService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {

    this.calculadoraService.initDetails(this.accesstoken)
      .subscribe( (data: LoanProposalModel) => {
        this.initData$.next({...data});
        this.initData = {...data};
        this.calculosData$.next(this.initData);
    });

    this.calculadoraService.amountOutOfBounds$
    .pipe(skip(1))
      .subscribe ( amountOutOfBounds => {
        this.amountOutOfBounds = !amountOutOfBounds
      });
  }

  setResume(calculatedValues) {
    this.initData = calculatedValues;
    this.amountOutOfBounds = false;
    this.calculosData$.next(this.initData);
  }

  setMonths(months) {
    this.initData.Deadlines = months;
    this.calculosData$.next(this.initData);
  }

  showListData(event) {
    this.toggleViewListData();
  }

  hideListData(event) {
    this.viewListData = event;
  }

  toggleViewListData() {
    this.viewListData = !this.viewListData;
  }

  loanRequest() {
    this.calculadoraService.getLoan(
      this.accesstoken,
      this.initData,
      this.initData['urlCall'] //TODO: Change this property with the right one sended in getCalculate request
    )
    .subscribe()
  }
}
